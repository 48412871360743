export const environment = {
  production: false,
  llqBpUrl: '.bptst.leerlinq.net',
  llqApUrl: '.tst.leerlinq.net',
  llqApPort: '',
  llqBpPort: '',
  keycloakConfig: {
    url: 'https://auth.tst.leerlinq.net',
    realm: 'mgmt',
    clientId: 'angular-beheerportaal',
  },
};
