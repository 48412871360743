<h2>Meldingen</h2>

<mat-card appearance="outlined">
  <mat-card-content>
    <div class="mat-elevation-z0">
      <table
        mat-table
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        aria-label="Selecteer"
      >
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Titel</th>
          <td mat-cell *matCellDef="let element">{{ element.subject }}</td>
        </ng-container>

        <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Prioriteit</th>
          <td mat-cell *matCellDef="let element">{{ element.priority }}</td>
        </ng-container>

        <ng-container matColumnDef="portalTypes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Portalen</th>
          <td mat-cell *matCellDef="let element">
            {{ element.portalTypes.join(", ") }}
          </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Actief</th>
          <td mat-cell *matCellDef="let element">
            {{ element.isActive | jaNee }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sort">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sortering</th>
          <td mat-cell *matCellDef="let element">{{ element.sort }}</td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aangemaakt</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date: "dd-MM-yyyy hh:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="modifiedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aangepast</th>
          <td mat-cell *matCellDef="let element">
            {{ element.modifiedAt | date: "dd-MM-yyyy hh:mm:ss" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="detail-row"
          (click)="goToAnnouncement(row)"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            Geen meldingen gevonden
          </td>
        </tr>
      </table>
      <mat-paginator
        [length]="pagingDTO.totalItems"
        [pageSize]="pagingDTO.pageSize"
        [pageIndex]="pagingDTO.currentPage"
        [pageSizeOptions]="[10, 20, 50, 100]"
        (page)="pageEvent = onPageChange($event)"
        [showFirstLastButtons]="true"
        aria-label="Selecteer"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button color="accent" (click)="goToAnnouncement(null)">
      <mat-icon>add</mat-icon> Toevoegen
    </button>
  </mat-card-actions>
</mat-card>
