import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';

import { Announcement } from '../../../shared/components/model/announcement';
import { AnnouncementService } from '../../../shared/components/services/announcement.service';
import { DialogService } from '../../../shared/components/services/dialog.service';

@Component({
  selector: 'app-announcement-edit',
  templateUrl: './announcement-edit.component.html',
  styleUrls: ['./announcement-edit.component.css'],
})
export class AnnouncementEditComponent implements OnInit {
  announcementId: string;
  announcement: Announcement = {
    subject: '',
  } as Announcement;
  announcementForm: FormGroup = this.fb.group({
    id: [{ value: null, disabled: true }],
    subject: [
      null,
      Validators.compose([Validators.maxLength(200), Validators.required]),
    ],
    portalTypes: [null, Validators.required],
    priority: [null, Validators.required],
    body: [null, Validators.maxLength(5000)],
    isActive: [null],
    sort: [null],
  });
  portalTypes: string[] = ['VO', 'PO'];
  priorities: string[] = ['INFO', 'WARN', 'ALERT'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private announcementService: AnnouncementService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.announcementId = this.route.snapshot.paramMap.get('id');
    if (this.announcementId !== 'new') {
      this.getAnnouncement();
    }
  }

  showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten', {
      duration: 5000,
    });
  }

  getAnnouncement(): void {
    this.announcementService
      .getAnnouncementById(this.announcementId)
      .pipe(
        tap({
          next: (response) => {
            if (response.status === 200) {
              this.announcement = response.body;
              this.announcementForm.patchValue(this.announcement);
            }
          },
          error: (error) => {
            if (error.status === 404) {
              this.showToast(
                'Melding met ID ' +
                  this.announcementId +
                  ' bestaat niet in de database.',
              );
            } else {
              this.showToast(
                'Er is iets fout gegaan bij het ophalen van melding met ID ' +
                  this.announcementId,
              );
            }
          },
        }),
      )
      .subscribe();
  }

  getFormValidationErrors(): void {
    Object.keys(this.announcementForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.announcementForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((_) => {});
      }
    });
  }

  save(): void {
    this.getFormValidationErrors();
    if (this.announcementForm.invalid) {
      this.showToast('Het formulier is niet volledig ingevuld.');
      return;
    }
    this.dialogService
      .confirm(
        'Indien deze melding op actief staat, zal hij hierna in alle beheerportalen te zien zijn. ' +
          'Weet u zeker dat u hem wilt opslaan?',
      )
      .pipe(take(1))
      .subscribe((_) => {
        this.announcementService
          .saveAnnouncement(this.announcementForm.getRawValue())
          .subscribe((_) => {
            this.showToast(
              'Melding ' +
                this.announcementForm.get('subject').value +
                ' is opgeslagen.',
            );
            this.back();
          });
      });
  }

  delete(): void {
    if (this.announcementId === 'new') {
      this.back();
      return;
    }
    this.dialogService
      .confirm(
        'Indien deze melding op actief stond, zal hij hierna niet meer zichtbaar zijn in de beheerportalen. ' +
          'Weet u zeker dat u hem wilt verwijderen?',
      )
      .pipe(take(1))
      .subscribe((_) => {
        this.announcementService
          .deleteAnnouncementById(this.announcementId)
          .pipe(take(1))
          .subscribe((_) => {
            this.showToast(
              'Melding ' +
                this.announcementForm.get('subject').value +
                ' is verwijderd.',
            );
            this.router.navigate(['announcement']);
          });
      });
  }

  back(): void {
    this.router.navigate(['announcement']);
  }
}
