import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ConnectLasInfoDialogComponent } from '../../../modules/contract/contract-edit/connect-las-info-dialog/connect-las-info-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmWithInputDialogComponent } from '../confirm-with-input-dialog/confirm-with-input-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  confirm(message: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      data: {
        message,
      },
    });

    return dialogRef.afterClosed().pipe(filter((value) => value === true));
  }

  open(message: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      data: {
        message,
      },
    });

    return dialogRef.afterClosed();
  }

  confirmInputDialog(
    message: string,
    patternType: string,
    patternToMatch: string,
    includeAudit: boolean,
    action: string,
  ): Observable<any> {
    const dialogRef = this.dialog.open(ConfirmWithInputDialogComponent, {
      minWidth: '300px',
      data: {
        message: message,
        patternType: patternType,
        patternToMatch: patternToMatch,
        includeAudit: includeAudit,
        action: action,
      },
    });

    return dialogRef
      .afterClosed()
      .pipe(filter((value): any => value.confirmed === true));
  }

  connectLasInfoDialog(
    contractId: string,
    contractName: string,
  ): Observable<any> {
    const dialogRef = this.dialog.open(ConnectLasInfoDialogComponent, {
      minWidth: '1650px',
      maxHeight: '1000px',
      data: {
        contractId: contractId,
        contractName: contractName,
      },
    });

    return dialogRef.afterClosed();
  }
}
