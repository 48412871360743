import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { BagAddress } from '../../../shared/components/model/adresses';
import { DuoSchool } from '../../../shared/components/model/duoSchool';
import { Postalcodelookup } from '../../../shared/components/model/postalcodelookup';
import { DuoSchoolService } from '../../../shared/components/services/duoSchool.service';

@Component({
  selector: 'app-contract-edit',
  templateUrl: './duoSchool-edit.component.html',
  styleUrls: ['./duoSchool-edit.component.css'],
})
export class DuoSchoolEditComponent implements OnInit {
  duoSchool: DuoSchool = {
    name: '',
  } as DuoSchool;

  duoSchoolForm: FormGroup = this.fb.group({
    alias: [null],
    brinNumber: [null, Validators.required],
    cityName: [null, Validators.required],
    fileType: [{ value: null, disabled: true }],
    houseNumber: [null, Validators.required],
    id: [{ value: null, disabled: true }],
    locationName: [{ value: null, disabled: true }],
    displayName: [null, Validators.required],
    locationNumber: [
      null,
      Validators.compose([Validators.required, Validators.maxLength(4)]),
    ],
    postalCode: [
      null,
      Validators.compose([
        Validators.required,
        Validators.pattern('^\\d{4}\\s?[A-Za-z]{2}$'),
      ]),
    ],
    schoolType: [null, Validators.required],
    searchField: [null],
    streetName: [null, Validators.required],
  });

  postalCodeLookup: Postalcodelookup = {} as Postalcodelookup;

  schoolTypesList = [
    { name: 'Primair Onderwijs', abbreviation: 'PO' },
    { name: 'Voortgezet Onderwijs', abbreviation: 'VO' },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private duoSchoolService: DuoSchoolService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public readonly dialogRef: MatDialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA) public readonly schoolId: string,
  ) {}

  ngOnInit(): void {
    if (this.schoolId !== undefined) {
      this.duoSchoolService
        .getDuoSchool(this.schoolId)
        .pipe(take(1))
        .subscribe((duoSchool) => {
          this.duoSchool = duoSchool;
          this.duoSchoolForm.patchValue(this.duoSchool);
        });
    }
  }

  save(): void {
    if (this.duoSchoolForm.invalid) return;

    if (this.schoolId !== undefined) {
      this.duoSchoolService
        .saveDuoSchool(this.duoSchoolForm.getRawValue())
        .pipe(take(1))
        .subscribe((data) => {
          this.showToast(data.brinNumber + ' is aangepast.');
          this.dialogRef.close();
        });
    } else {
      this.duoSchoolService
        .addDuoSchool(this.duoSchoolForm.getRawValue())
        .pipe(take(1))
        .subscribe((data) => {
          this.showToast(data.brinNumber + ' is toegevoegd.');
          this.dialogRef.close();
        });
    }
  }

  delete(): void {
    if (this.schoolId !== undefined) {
      if (this.duoSchool.fileType == 'manual') {
        this.duoSchoolService
          .deleteDuoSchool(this.duoSchool)
          .pipe(take(1))
          .subscribe((_) => {
            this.showToast(this.duoSchool.brinNumber + ' is verwijderd.');
            this.dialogRef.close();
          });
      }
    }
  }

  checkPostalCode(): void {
    const postalCode = this.duoSchoolForm
      .get('postalCode')
      .value.replace(/\s+/, '')
      .toUpperCase();
    const houseNumberMatch = this.duoSchoolForm
      .get('houseNumber')
      .value.match(/\d+/);

    if (postalCode.length !== 6 || !houseNumberMatch) {
      return;
    }

    this.postalCodeLookup.postalCode = postalCode;
    this.postalCodeLookup.houseNumber = Number(houseNumberMatch[0]);

    this.duoSchoolService
      .getAddresses(this.postalCodeLookup)
      .pipe(take(1))
      .subscribe((data: BagAddress) => {
        if (data !== undefined) {
          this.duoSchoolForm.get('streetName').setValue(data.streetName);
          this.duoSchoolForm.get('cityName').setValue(data.cityName);
        }
      });
  }

  public showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten');
  }
}
