<h2>Actieve patches</h2>

<mat-card appearance="outlined">
  <mat-card-actions align="end">
    <button mat-raised-button color="accent" (click)="checkForPatches()">
      <mat-icon>sync</mat-icon>
    </button>
  </mat-card-actions>
  <mat-card-content>
    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="patchNr">
          <th mat-header-cell *matHeaderCellDef>PatchNr</th>
          <td mat-cell *matCellDef="let element">{{ element.patchNr }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Beschrijving</th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>

        <ng-container matColumnDef="isCompleted">
          <th mat-header-cell *matHeaderCellDef>Afgerond</th>
          <td mat-cell *matCellDef="let element">{{ element.isCompleted }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          class="detail-row"
          (click)="goToPatch(row)"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[10, 20, 50, 100]"
        pageSize="20"
        showFirstLastButtons
        aria-label="Selecteer"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
