<h2>Nationaliteiten</h2>
<mat-card appearance="outlined">
  <mat-card-content>
    <mat-form-field class="full-width">
      <mat-label>Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Ex. Mia"
        #input
      />
    </mat-form-field>

    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dataSource" aria-label="Selecteer">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Code</th>
          <td mat-cell *matCellDef="let element">
            {{ element.nationalityCode }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Naam</th>
          <td mat-cell *matCellDef="let element">
            {{ element.nationalityName }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="non-clickable-row"
        ></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[10, 20, 50, 100]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
