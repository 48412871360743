<h2>
  Melding: {{ announcementId === "new" ? "Nieuw" : announcement.subject }}
</h2>
<mat-card appearance="outlined">
  <mat-card-content>
    <form [formGroup]="announcementForm">
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>ID</mat-label>
            <input matInput formControlName="id" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Portaal</mat-label>
            <mat-select formControlName="portalTypes" multiple>
              <mat-option
                *ngFor="let portalType of portalTypes"
                [value]="portalType"
                >{{ portalType }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Prioriteit</mat-label>
            <mat-select formControlName="priority">
              <mat-option
                *ngFor="let priority of priorities"
                [value]="priority"
                >{{ priority }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Sortering</mat-label>
            <input matInput placeholder="999" formControlName="sort" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Titel</mat-label>
            <input matInput formControlName="subject" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Inhoud</mat-label>
            <textarea
              matInput
              placeholder="Voer hier het bericht in"
              formControlName="body"
              rows="6"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-checkbox class="full-width" formControlName="isActive">
            Is actief
          </mat-checkbox>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button (click)="back()">
      <mat-icon>cancel</mat-icon> Annuleren
    </button>
    <button mat-raised-button color="warn" (click)="delete()">
      <mat-icon>delete</mat-icon> Verwijderen
    </button>
    <button mat-raised-button color="primary" (click)="save()">
      <mat-icon>save</mat-icon> Opslaan
    </button>
  </mat-card-actions>
</mat-card>
