<h2 xmlns="http://www.w3.org/1999/html">Contacten</h2>
<div class="mat-elevation-z1">
  <table mat-table aria-label="Selecteer" [dataSource]="contactList">
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>Voornaam</th>
      <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="middleName">
      <th mat-header-cell *matHeaderCellDef>Tussenvoegsel</th>
      <td mat-cell *matCellDef="let element">{{ element.middleName }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>Achternaam</th>
      <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>e-mail</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteContact(element)">
            <mat-icon>delete</mat-icon>
            <span>Verwijder</span>
          </button>
          <button mat-menu-item (click)="setKcUser(element)">
            <mat-icon>add_user</mat-icon>
            <span>Maak beheerder</span>
          </button>
          <button mat-menu-item (click)="setSupportUser(element)">
            <mat-icon>support</mat-icon>
            <span>Toevoegen bij Zendesk</span>
          </button>
          <button mat-menu-item (click)="removeSupportUser(element)">
            <mat-icon>delete</mat-icon>
            <span>Verwijderen bij Zendesk</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayColsContacts"></tr>
    <tr
      mat-row
      (click)="goToContact(row)"
      *matRowDef="let row; columns: displayColsContacts"
      class="detail-row"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Geen data om te tonen.</td>
    </tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[10, 20]"
    showFirstLastButtons
    aria-label="Selecteer contactpersoon"
  >
  </mat-paginator>
</div>
<mat-card-actions align="end">
  <button
    mat-raised-button
    color="primary"
    [disabled]="contractID === 'new'"
    (click)="addLeerlinqSupport()"
  >
    Impersonate
  </button>
  <button
    mat-raised-button
    color="accent"
    [disabled]="contractID === 'new'"
    (click)="goToContact(null)"
  >
    Toevoegen
  </button>
</mat-card-actions>

<ng-template #contactTpl>
  <h2 mat-dialog-title>Contactpersoon</h2>
  <mat-dialog-content>
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Voornaam"
              formControlName="firstName"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Tussenvoegsel"
              formControlName="middleName"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Achternaam"
              formControlName="lastName"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="e-mailadres" formControlName="email" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Rol</mat-label>
            <mat-select formControlName="type">
              <mat-option [value]="'TECHNICAL'">Technisch</mat-option>
              <mat-option [value]="'COMMERCIAL'">Commercieël</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <textarea
              matInput
              placeholder="Opmerkingen"
              formControlName="remarks"
            >
            </textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close="true">
      <mat-icon>cancel</mat-icon> Annuleren
    </button>
    <button mat-raised-button color="primary" (click)="save()">
      <mat-icon>save</mat-icon> Opslaan
    </button>
  </mat-dialog-actions>
</ng-template>
