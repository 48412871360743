<h2>DUO Tabel</h2>

<mat-card appearance="outlined">
  <mat-card-content>
    <mat-form-field class="full-width">
      <mat-label>Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Ex. Mia"
        #input
      />
    </mat-form-field>

    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="brinNumber">
          <th mat-header-cell *matHeaderCellDef>Code</th>
          <td mat-cell *matCellDef="let element">{{ element.brinNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef>Naam</th>
          <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
        </ng-container>

        <ng-container matColumnDef="alias">
          <th mat-header-cell *matHeaderCellDef>Alias</th>
          <td mat-cell *matCellDef="let element">{{ element.alias }}</td>
        </ng-container>

        <ng-container matColumnDef="schoolType">
          <th mat-header-cell *matHeaderCellDef>Schooltype</th>
          <td mat-cell *matCellDef="let element">{{ element.schoolType }}</td>
        </ng-container>

        <ng-container matColumnDef="fileType">
          <th mat-header-cell *matHeaderCellDef>Filetype</th>
          <td mat-cell *matCellDef="let element">{{ element.fileType }}</td>
        </ng-container>

        <ng-container matColumnDef="cityName">
          <th mat-header-cell *matHeaderCellDef>Plaats</th>
          <td mat-cell *matCellDef="let element">{{ element.cityName }}</td>
        </ng-container>

        <ng-container matColumnDef="locationName">
          <th mat-header-cell *matHeaderCellDef>DUO Naam</th>
          <td mat-cell *matCellDef="let element">{{ element.locationName }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          (click)="goToSchool(row)"
          *matRowDef="let row; columns: displayedColumns"
          class="detail-row"
          [ngClass]="{
            'detail-row': true,
            deleted: row.deletedAt,
            manual: row.fileType === 'manual'
          }"
        ></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 15, 20, 25]"
        pageSize="15"
        showFirstLastButtons
        aria-label="Selecteer"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button color="accent" (click)="goToSchool(null)">
      <mat-icon>add</mat-icon> Toevoegen
    </button>
  </mat-card-actions>
</mat-card>
