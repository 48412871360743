<h2>Event</h2>

<mat-card appearance="outlined" class="event-card">
  <mat-card-content>
    <form [formGroup]="eventForm">
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Action" formControlName="action" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="ActionType"
              formControlName="actionType"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Contract Nr"
              formControlName="contractNr"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Identifier (AanmeldNr)"
              formControlName="identifier"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Queue" formControlName="queue" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Message" formControlName="msg" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button color="primary" (click)="reset()">
      <mat-icon>cancel</mat-icon> Annuleren
    </button>
    <button mat-raised-button color="warn" (click)="send()">
      <mat-icon>commit</mat-icon> Versturen
    </button>
  </mat-card-actions>
</mat-card>
