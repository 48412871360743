<h2>Addressen</h2>

<mat-card appearance="outlined">
  <mat-card-content>
    <mat-form-field class="full-width">
      <mat-label>Zoeken</mat-label>
      <input
        matInput
        (keyup.enter)="filterDatatable($event)"
        placeholder=""
        #input
      />
    </mat-form-field>
    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dataSource" aria-label="Selecteer">
        <ng-container matColumnDef="postalcode">
          <th mat-header-cell *matHeaderCellDef>Postcode</th>
          <td mat-cell *matCellDef="let element">{{ element.postalCode }}</td>
        </ng-container>

        <ng-container matColumnDef="streetname">
          <th mat-header-cell *matHeaderCellDef>Straat</th>
          <td mat-cell *matCellDef="let element">{{ element.streetName }}</td>
        </ng-container>

        <ng-container matColumnDef="cityname">
          <th mat-header-cell *matHeaderCellDef>Plaats</th>
          <td mat-cell *matCellDef="let element">{{ element.cityName }}</td>
        </ng-container>

        <ng-container matColumnDef="provincename">
          <th mat-header-cell *matHeaderCellDef>Provincie</th>
          <td mat-cell *matCellDef="let element">{{ element.provinceName }}</td>
        </ng-container>

        <ng-container matColumnDef="minHouseNumber">
          <th mat-header-cell *matHeaderCellDef>Min Huisnummer</th>
          <td mat-cell *matCellDef="let element">
            {{ element.minHouseNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="maxHouseNumber">
          <th mat-header-cell *matHeaderCellDef>Max Huisnummer</th>
          <td mat-cell *matCellDef="let element">
            {{ element.maxHouseNumber }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="non-clickable-row"
        ></tr>
      </table>
      <mat-paginator
        [length]="pagingDTO.totalItems"
        [pageSize]="pagingDTO.pageSize"
        [pageIndex]="pagingDTO.currentPage"
        [pageSizeOptions]="[10, 20, 50, 100]"
        (page)="pageEvent = onPageChange($event)"
        showFirstLastButtons
        aria-label="Selecteer"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
