import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CustomValidators } from '../validators/custom-validator';

@Component({
  selector: 'app-confirm-with-input-dialog',
  templateUrl: './confirm-with-input-dialog.component.html',
  styleUrls: ['./confirm-with-input-dialog.component.css'],
})
export class ConfirmWithInputDialogComponent implements OnInit {
  myForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConfirmWithInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private clipboard: Clipboard,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.myForm = this.fb.group({
      inputNumber: [
        '',
        CustomValidators.matchesPattern(this.data.patternToMatch),
      ],
      auditMessage: [
        '',
        Validators.compose([this.includeAudit ? Validators.required : null]),
      ],
    });
  }

  showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten', {
      duration: 5000,
    });
  }

  cancel(): void {
    this.dialogRef.close({
      confirmed: false,
      audit: this.myForm.get('auditMessage').value,
    });
  }

  confirm(): void {
    this.dialogRef.close({
      confirmed: true,
      audit: this.myForm.get('auditMessage').value,
    });
  }

  copyPattern(): void {
    this.clipboard.copy(this.data.patternToMatch);
  }

  get patternToMatch(): string {
    return this.data.patternToMatch;
  }

  get patternType(): string {
    return this.data.patternType;
  }

  get includeAudit(): boolean {
    return this.data.includeAudit;
  }
}
