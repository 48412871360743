import { Component } from '@angular/core';

import { Databrickstable } from '../../../shared/components/model/databrickstable';
import { UpdatedbService } from '../../../shared/components/services/updatedb.service';
import {Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {DuoSchool} from "../../../shared/components/model/duoSchool";
import {DuoSchoolService} from "../../../shared/components/services/duoSchool.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-duo-sync',
  templateUrl: './duo-sync.component.html',
  styleUrls: ['./duo-sync.component.css'],
})
export class DuoSyncComponent {
  dataSource = new MatTableDataSource<DuoSchool>();

  duoSync: DuoSync;

  displayedColumns: string[] = [
    'brinNumber',
    'displayName',
    'alias',
    'schoolType',
    'fileType',
    'cityName',
    'locationName',
  ];

  constructor(private duoService: DuoSchoolService) {
    this.syncSchools();
  }

  syncSchools(): void {
    this.duoService.syncSchools()
      .subscribe((duoSync) => {
        this.duoSync = duoSync;
      });
  }

}

export class DuoSync {
  updated: DuoSchool[];
  removed: DuoSchool[];
  added: DuoSchool[];
  manualDeleted: DuoSchool[];
  doubleBrinType: DuoSchool[];
}
