import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {PagingDTO} from "../model/paging";
import {Observable} from "rxjs";
import {ReportDTO} from "../model/report";

@Injectable()
export class ReportService {
  private reportUrl = 'api/report/';

  constructor(private http: HttpClient) {}

  getReports(filter: PagingDTO): Observable<ReportDTO> {
    return this.http.post<ReportDTO>(this.reportUrl, filter);
  }

  getReport(reportId: number, accept: string) {
    const params = new HttpParams().append("reportId", reportId.toString())
    const headers = new HttpHeaders().set('Accept', accept);
    return this.http
      .get(this.reportUrl + "export", { headers: headers, params: params, responseType: 'blob' });
  }
}
