import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Filter } from '../model/filter';
import { LoggingsDTO } from '../model/logging';

@Injectable()
export class LoggingService {
  loggingUrl = '/api/logging/';

  constructor(private http: HttpClient) {}

  getLoggingItems(filter: Filter): Observable<LoggingsDTO> {
    // post call to api/logging/
    return this.http.post<LoggingsDTO>(this.loggingUrl, filter);
  }

  deleteLoggingItem(id: string, auditMessage: string): Observable<boolean> {
    // delete call to api/logging/ID
    return this.http.delete<boolean>(this.loggingUrl.concat(id), {
      body: auditMessage,
    });
  }
}
