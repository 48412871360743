import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { NationalityCode } from '../../shared/components/model/bagData';
import { BagDataService } from '../../shared/components/services/bagData.service';

@Component({
  selector: 'app-nationality',
  templateUrl: './nationality.component.html',
  styleUrls: ['./nationality.component.css'],
})
export class NationalityCodeComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<NationalityCode>();
  displayedColumns: string[] = ['code', 'name'];

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  constructor(private router: Router, private bagDataService: BagDataService) {
    this.getNationalityCodes();
  }

  getNationalityCodes(): void {
    this.bagDataService.getNationalityCodes().subscribe((nationalityCodes) => {
      this.dataSource.data = nationalityCodes;
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
