import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { Contract } from '../../../shared/components/model/contract';
import { Patch } from '../../../shared/components/model/patch';
import { PatchLog } from '../../../shared/components/model/patchLog';
import { ContractService } from '../../../shared/components/services/contract.service';
import { PatchService } from '../../../shared/components/services/patch.service';

@Component({
  selector: 'app-patch-detail',
  templateUrl: './patch-detail.component.html',
  styleUrls: ['./patch-detail.component.scss'],
})
export class PatchDetailComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<Contract>();

  displayedColumns: string[] = [
    'contractNr',
    'contractName',
    'patchLog',
    'actions',
  ];

  patch: Patch;
  patchLogs: PatchLog[];
  contracts: Contract[];

  patchId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contractService: ContractService,
    private patchService: PatchService,
    private _snackBar: MatSnackBar
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.patchId = this.route.snapshot.paramMap.get('id');

    this.patchService
      .getPatch(this.patchId)
      .pipe(take(1))
      .subscribe((patch) => {
        this.patch = patch;
        this.getContracts();
      });
  }

  getContracts(): void {
    this.contractService
      .getContracts()
      .pipe(take(1))
      .subscribe((contracts) => {
        this.contracts = contracts;
        this.getLogs();
      });
  }

  getLogs(): void {
    this.patchService
      .getPatchLogs(this.patchId)
      .pipe(take(1))
      .subscribe((patchLogs) => {
        this.patchLogs = patchLogs;
        this.updateContractLogs();
      });
  }

  updateContractLogs(): void {
    this.contracts.forEach((c) => {
      c.patchLog = this.patchLogs
        .filter((l) => l.contractNr == c.contractNr)
        .pop();
    });

    this.dataSource.data = this.contracts;
  }

  back(): void {
    this.router.navigate(['/patch']);
  }

  /*
   fires the event run for contract
   */
  runPatchForContract(contractNr: number): void {
    this.patchService.runPatch(this.patch, contractNr).subscribe((data) => {
      this.showToast('Run patch voor contract ' + contractNr);
      setTimeout(() => {
        this.getLogsForContract(contractNr);
      }, 2000);
    });
  }

  getLogsForContract(contractNr: number): void {
    this.patchService
      .getPatchLogsForContract(this.patch.id, contractNr)
      .pipe(take(1))
      .subscribe((patchLogs) => {

        if (patchLogs && patchLogs.length > 0) {
          patchLogs.length = 1;
          this.contracts.find((c) => c.contractNr == contractNr).patchLog =
            patchLogs.pop();
        }
      });
  }

  public showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten');
  }
}
