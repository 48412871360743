<h2>
  Aanmelding {{ signUpId }} ({{
    contractNr ? contractNr : "contract onbekend"
  }})
</h2>
<mat-card appearance="outlined">
  <mat-card-content>
    <form [formGroup]="form">
      <json-editor [options]="editorOptions" formControlName="myinput" #editor>
      </json-editor>
      <mat-card-actions align="end">
        <button mat-raised-button (click)="goBack()">
          <mat-icon>cancel</mat-icon> Annuleren
        </button>
        <button mat-raised-button color="accent" (click)="reset()">
          <mat-icon>sync</mat-icon> Resetten
        </button>
        <button mat-raised-button color="primary" (click)="submit()">
          <mat-icon>save</mat-icon> Opslaan
        </button>
        <button mat-raised-button color="warn" (click)="deleteLog()">
          <mat-icon>delete</mat-icon> Verwijder uit Uitvallijst
        </button>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>
