<h1 mat-dialog-title>Bevestigen</h1>
<div mat-dialog-content>
  <p style="margin-bottom: 2px">{{ data.message }}</p>
  <div style="display: flex; align-items: center">
    <p style="margin: 0">
      Voer {{ patternType }} in om te bevestigen: {{ patternToMatch }}
    </p>
    <button
      (click)="copyPattern()"
      mat-icon-button
      aria-label="kopiëren"
      matSuffix
      matTooltip="Kopieer {{ patternType }}"
      style="margin-left: 8px"
    >
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
  <mat-form-field class="full-width" [formGroup]="myForm">
    <input matInput formControlName="inputNumber" />
  </mat-form-field>
  <mat-form-field class="full-width" [formGroup]="myForm" *ngIf="includeAudit">
    <mat-label>Reden van {{ data.action }}</mat-label>
    <textarea
      rows="4"
      matInput
      type="text"
      required
      placeholder="Notitie"
      formControlName="auditMessage"
    >
    </textarea>
  </mat-form-field>
  <div mat-dialog-actions [align]="'end'">
    <button mat-raised-button (click)="cancel()">
      <mat-icon>clear</mat-icon> Annuleren
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="myForm.invalid"
      (click)="confirm()"
    >
      <mat-icon>check</mat-icon> Bevestigen
    </button>
  </div>
</div>
