import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'jaNee',
  standalone: true,
})
export class JaNeePipe implements PipeTransform {
  transform(value: boolean): string {
    switch (value) {
      case false:
        return 'Nee';
      case true:
        return 'Ja';
      default:
        return '';
    }
  }
}
