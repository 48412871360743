import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Databrickstable } from '../model/databrickstable';

@Injectable()
export class UpdatedbService {
  dbUrl = '/api/updatedb/';

  constructor(private http: HttpClient) {}

  getTables() {
    return this.http.get<Databrickstable[]>(this.dbUrl);
  }

  importAllTables(): Observable<void> {
    return this.http.post<void>(this.dbUrl + 'import', null);
  }

  importTable(table: string): Observable<void> {
    return this.http.post<void>(this.dbUrl + 'import/' + table, null);
  }

  processAllTables(): Observable<void> {
    return this.http.post<void>(this.dbUrl + 'process', null);
  }

  processTable(table: string): Observable<void> {
    return this.http.post<void>(this.dbUrl + 'process/' + table, null);
  }
}
