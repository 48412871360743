<mat-dialog-content>
  <mat-card appearance="outlined" class="flex">
    <mat-card-header>
      <mat-card-title>
        <mat-icon>mode_edit_outline</mat-icon>
        Contract: {{ data.contractName }}
      </mat-card-title>
      <mat-card-subtitle><br /></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group
        animationDuration="0ms"
        [mat-stretch-tabs]="false"
        mat-align-tabs="start"
        (selectedTabChange)="resetSelectedIds()"
      >
        <mat-tab
          *ngFor="let schoolYear of schoolYears"
          label="{{ schoolYear }}"
        >
          <mat-list>
            <mat-list-item>
              <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                  >
                    <strong>School</strong>
                  </div>
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                  >
                    <strong>Instelling/Tenant</strong>
                  </div>
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                  >
                    <strong>Vestiging</strong>
                  </div>
                </div>
              </div>
            </mat-list-item>
            <mat-list-item
              *ngFor="
                let school of schoolConfigurations.get(schoolYear);
                let i = index
              "
            >
              <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                  >
                    <span>{{ school.name + " (" + school.brin + ")" }}</span>
                  </div>
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                  >
                    <div>
                      <mat-form-field class="full-width">
                        <mat-select [(ngModel)]="selectedLasIds[i]">
                          <ng-container
                            *ngFor="
                              let value of getUniqueLasIdsMapValues();
                              let j = index
                            "
                          >
                            <mat-option
                              [value]="getUniqueLasIdsMapKeys()[j]"
                              *ngIf="value.lasType === school.lasType"
                              >{{ value.lasName }}</mat-option
                            >
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                  >
                    <div>
                      <mat-form-field class="full-width">
                        <mat-select
                          [(ngModel)]="selectedLasLocationIds[i]"
                          [disabled]="!selectedLasIds[i]"
                        >
                          <ng-container
                            *ngFor="let info of sortByLasLocationName()"
                          >
                            <mat-option
                              [value]="info.lasLocationId"
                              *ngIf="info.lasId === selectedLasIds[i]"
                              >{{ info.lasLocationName }}</mat-option
                            >
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-1"
                  >
                    <div>
                      <button
                        mat-icon-button
                        color="primary"
                        (click)="
                          updateSchoolConfigurationLasInfo(schoolYear, i)
                        "
                        [disabled]="!selectedLasLocationIds[i]"
                        *ngIf="
                          school.lasId === null || school.lasId === undefined
                        "
                      >
                        <mat-icon>save</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-list-item>
            <mat-list-item
              *ngFor="
                let school of connectedSchools.get(schoolYear);
                let i = index
              "
            >
              <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                  >
                    <span>{{ school.name + " (" + school.brin + ")" }}</span>
                  </div>
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                  >
                    <div>
                      <ng-container>
                        <span>{{ getLasNameByLasId(school.lasId) }}</span>
                      </ng-container>
                    </div>
                  </div>
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"
                  >
                    <div>
                      <ng-container
                        *ngIf="
                          school.lasLocationId !== null &&
                            school.lasLocationId !== undefined;
                          else lasLocationNameElse
                        "
                      >
                        <span>{{
                          getLasLocationNameByLasLocationId(
                            school.lasLocationId
                          )
                        }}</span>
                      </ng-container>
                      <ng-template #lasLocationNameElse>
                        <span>Onbekend</span>
                      </ng-template>
                    </div>
                  </div>
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-1"
                  >
                    <div>
                      <button
                        mat-icon-button
                        color="warn"
                        (click)="emptySchoolConfigurationLasInfo(schoolYear, i)"
                      >
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-raised-button color="primary" (click)="updateDbLasInfo()">
        <mat-icon>sync</mat-icon> LAS informatie bijwerken
      </button>
      <button mat-raised-button (click)="cancel()">
        <mat-icon>clear</mat-icon> Annuleren
      </button>
    </mat-card-actions>
  </mat-card>
</mat-dialog-content>
