import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { PagingDTO } from '../../shared/components/model/paging';
import { AnnouncementService } from '../../shared/components/services/announcement.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css'],
})
export class AnnouncementComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'subject',
    'priority',
    'portalTypes',
    'isActive',
    'sort',
    'createdAt',
    'modifiedAt',
  ];

  pagingDTO: PagingDTO = {
    totalItems: 0,
    currentPage: 0,
    limit: 0,
    pageSize: 20,
    totalPages: 0,
    orderColumn: 'modifiedAt',
    orderDir: 'desc',
  } as PagingDTO;
  pageEvent: PageEvent;

  constructor(
    private announcementService: AnnouncementService,
    private router: Router,
  ) {}

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Aantal per pagina';
    this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe((sortInfo) => {
      this.onSort(sortInfo);
    });
    this.getAnnouncements();
  }

  onSort(sortInfo): void {
    this.pagingDTO.orderColumn = sortInfo.active;
    this.pagingDTO.orderDir = sortInfo.direction;

    this.getAnnouncements();
  }

  getAnnouncements() {
    this.announcementService
      .getAnnouncementsByFilter(this.pagingDTO)
      .subscribe((dto) => {
        this.pagingDTO.totalItems = dto.totalItems;
        this.pagingDTO.currentPage = dto.currentPage;
        this.dataSource.data = dto.announcements;
      });
  }

  onPageChange(e: PageEvent) {
    this.pagingDTO.currentPage = e.pageIndex;
    this.pagingDTO.pageSize = e.pageSize;
    this.getAnnouncements();
    return e;
  }

  goToAnnouncement(row: any) {
    if (row === null) {
      this.router.navigate(['/announcement/new']);
      return;
    } else {
      this.router.navigate(['/announcement', row.id]);
    }
  }
}
