import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { take, tap } from 'rxjs/operators';

import { SignUp } from '../../shared/components/model/signup';
import { DialogService } from '../../shared/components/services/dialog.service';
import { SignupService } from '../../shared/components/services/signup.service';

@Component({
  selector: 'app-signup-edit',
  templateUrl: './signup-edit.component.html',
  styleUrls: ['./signup-edit.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JsonEditorComponent),
      multi: true,
    },
  ],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupEditComponent implements OnInit {
  public editorOptions: JsonEditorOptions;
  public data: any;
  @ViewChild('editor', { static: false })
  editor: JsonEditorComponent;
  form: FormGroup;
  signUpId: string;
  contractNr: string;
  private unmodified: SignUp;

  constructor(
    private fb: FormBuilder,
    private _location: Location,
    private readonly route: ActivatedRoute,
    private signupService: SignupService,
    private cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private dialogService: DialogService,
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.signUpId = this.route.snapshot.paramMap.get('id');
    this.form = this.fb.group({
      myinput: [{}],
    });
  }

  ngOnInit(): void {
    this.getContractNr();
    this.getSignUp();
    this.initEditorOptions(this.editorOptions);
  }

  showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten', {
      duration: 5000,
    });
  }

  getSignUp(): void {
    this.signupService
      .getSignUp(this.signUpId)
      .pipe(
        tap({
          next: (response) => {
            if (response.status === 200) {
              let signUp: SignUp = response.body;
              this.data = signUp;
              this.unmodified = signUp;
              this.form = this.fb.group({
                myinput: [this.data],
              });
              this.cdr.detectChanges();
            }
          },
          error: (error) => {
            if (error.status === 404) {
              this.showToast(
                'Aanmelding met AanmeldNr ' +
                  this.signUpId +
                  ' bestaat niet in de database.',
              );
            } else {
              this.showToast(
                'Er is iets fout gegaan bij het ophalen van statistiek met AanmeldNr ' +
                  this.signUpId,
              );
            }
          },
        }),
      )
      .subscribe();
  }

  getContractNr(): void {
    this.signupService
      .getContractNrBySignUpId(this.signUpId)
      .subscribe((contractNr) => {
        this.contractNr = contractNr;
      });
  }

  submit(): void {
    this.dialogService
      .confirmInputDialog(
        'U staat op het punt om een aanmelding te wijzigen. ' +
          'Weet u zeker dat u door wilt gaan?',
        'het AanmeldNr',
        this.signUpId,
        true,
        'wijziging',
      )
      .pipe(take(1))
      .subscribe((value) => {
        let updatedSignUp: SignUp = JSON.parse(
          JSON.stringify(this.editor.get()),
        );
        this.signupService
          .updateSignUp(updatedSignUp, value.audit)
          .subscribe((_) => {
            this.showToast('Aanmelding gewijzigd.');
            this.goBack();
          });
      });
  }

  reset(): void {
    this.form = this.fb.group({
      myinput: [this.unmodified],
    });
    this.showToast('Aanmelding gereset.');
  }

  initEditorOptions(editorOptions: JsonEditorOptions): void {
    editorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
    editorOptions.onCreateMenu = (items: Array<any>, _) => {
      return items;
    };
    editorOptions.statusBar = true;
    editorOptions.navigationBar = true;
    editorOptions.mainMenuBar = true;
  }

  goBack() {
    this._location.back();
  }

  deleteLog() {
    this.dialogService
      .confirmInputDialog(
        'U staat op het punt om een aanmelding uit de uitvallijst te verwijderen. ' +
          'Weet u zeker dat u door wilt gaan?',
        'het AanmeldNr',
        this.signUpId,
        true,
        'verwijdering',
      )
      .pipe(take(1))
      .subscribe((value) => {
        this.signupService
          .deleteLoggingItemBySignUpId(this.signUpId, value.audit)
          .pipe(
            tap({
              next: (_) => {
                this.showToast('Aanmelding is verwijderd uit de uitvallijst.');
                this.goBack();
              },
              error: (error) => {
                if (error.status === 404) {
                  this.showToast(
                    'Error: Aanmelding staat niet in de uitvallijst.',
                  );
                } else {
                  this.showToast(
                    'Error: Aanmelding is niet verwijderd uit de uitvallijst.',
                  );
                }
              },
            }),
          )
          .subscribe();
      });
  }
}
