import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from 'src/app/shared/components/model/contract';
import { ContractService } from 'src/app/shared/components/services/contract.service';
import { CustomValidators } from 'src/app/shared/components/validators/custom-validator';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent {
  formGroup: FormGroup;
  displayColsContacts: string[] = [
    'firstName',
    'middleName',
    'lastName',
    'email',
    'actions',
  ];

  @Input() addressList: Address[] = [];
  @Input() contractID: string = '';

  @ViewChild('addressToast') addressToast;

  constructor(
    private fb: FormBuilder,
    private contractService: ContractService
  ) {}

  initFormGroup() {
    const formGroup = this.fb.group({
      id: [''],
      type: ['POSTAL', Validators.required],
      country: [''],
      street: ['', Validators.required],
      houseNumber: ['w', [CustomValidators.isNumber()]],
      houseNumberAddition: [''],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
    });
    return formGroup;
  }

  patchFormGroup(address: Address) {
    this.formGroup.patchValue({
      id: address.id,
      type: address.type,
      country: address.country,
      street: address.street,
      houseNumber: address.houseNumber,
      houseNumberAddition: address.houseNumberAddition,
      postalCode: address.postalCode,
      city: address.city,
    });
  }

  save(): void {
    const value = this.formGroup.value;
    const address: Address = {
      id: value.id,
      type: value.type,
      country: value.country,
      street: value.street,
      houseNumber: value.houseNumber,
      houseNumberAddition: value.houseNumberAddition,
      postalCode: value.postalCode,
      city: value.city,
    };
    if (!!address.id) {
      this.update(address);
    } else {
      this.create(address);
    }
  }

  update(address: Address) {
    this.contractService
      .updateAddress(this.contractID, address)
      .subscribe((contract) => {
        this.addressList = contract.addresses;
      });
  }

  create(contact: Address) {
    this.contractService
      .addAddress(this.contractID, contact)
      .subscribe((contract) => {
        this.showToast(
          'Adres ' +
            contact.street +
            ' ' +
            contact.houseNumber +
            'is toegevoegd.'
        );
        this.addressList = contract.addresses;
      });
  }

  delete(contact: Address) {
    this.contractService
      .deleteAddress(this.contractID, contact)
      .subscribe((contract) => {
        this.showToast(
          'Adres ' +
            contact.street +
            ' ' +
            contact.houseNumber +
            ' is verwijderd.'
        );
      });
  }

  actionBegin(args): void {
    if (args.requestType === 'beginEdit') {
      this.formGroup = this.initFormGroup();
      const contact = args.rowData as Address;
      this.patchFormGroup(contact);
    }
    if (args.requestType === 'add') {
      this.formGroup = this.initFormGroup();
    }
    if (args.requestType === 'save') {
      this.formGroup.markAllAsTouched();
      if (this.formGroup.valid) {
        this.save();
      } else {
        args.cancel = true;
      }
    }
    if (args.requestType === 'delete') {
      this.delete(args.data[0]);
    }
  }

  public showToast(message: string): void {}
}
