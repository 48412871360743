<h2 mat-dialog-title>
  DUO {{ schoolId === "new" ? "toevoegen" : "bewerken" }}
</h2>
<mat-dialog-content>
  <div *ngIf="duoSchool.fileType === 'manual'" class="alert alert-warning">
    Deze school is handmatig toegevoegd!
  </div>
  <form [formGroup]="duoSchoolForm">
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Naam" formControlName="displayName" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="DUO Naam" formControlName="locationName" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="SchoolID" formControlName="id" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Aliassen" formControlName="alias" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="BRIN6" formControlName="brinNumber" />
          <mat-error
            *ngIf="duoSchoolForm.controls['brinNumber'].hasError('required')"
          >
            BRIN is <strong>verplicht</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="BRIN4"
            formControlName="locationNumber"
          />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <mat-select
            placeholder="Soort onderwijs"
            formControlName="schoolType"
          >
            <mat-option
              *ngFor="let schoolTypeVal of schoolTypesList"
              [value]="schoolTypeVal.abbreviation"
            >
              {{ schoolTypeVal.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="duoSchoolForm.controls['schoolType'].hasError('required')"
          >
            Soort onderwijs is <strong>verplicht</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="FileType" formControlName="fileType" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Zoekveld"
            formControlName="searchField"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Postcode"
            formControlName="postalCode"
            pattern="^\d{4}\s?[A-Za-z]{2}$"
          />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Huisnummer"
            (keyup)="checkPostalCode()"
            formControlName="houseNumber"
          />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Straat" formControlName="streetName" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="Plaats" formControlName="cityName" />
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    color="warn"
    mat-raised-button
    *ngIf="schoolId !== 'new' && duoSchool.fileType === 'manual'"
    (click)="delete()"
  >
    Verwijderen
  </button>
  <button mat-raised-button mat-dialog-close="true">Annuleren</button>
  <button color="primary" mat-raised-button (click)="save()">Opslaan</button>
</mat-dialog-actions>
