import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuditDTO, AuditFilter } from '../model/audit';

@Injectable()
export class AuditService {
  private auditUrl = 'api/audit/'; // URL to web api

  constructor(private http: HttpClient) {}

  getAudits(filter: AuditFilter): Observable<AuditDTO> {
    return this.http.post<AuditDTO>(this.auditUrl, filter);
  }

  getObjecttypes(): Observable<any> {
    return this.http.get(this.auditUrl + 'objecttype');
  }

  getActions(): Observable<any> {
    return this.http.get(this.auditUrl + 'action');
  }

  getAccounts(): Observable<any> {
    return this.http.get(this.auditUrl + 'account');
  }
}
