import { Component, Input } from '@angular/core';

import { MenuItem } from '../model/menu-item';

@Component({
  selector: 'app-header-menu-item',
  templateUrl: './header-menu-item.component.html',
})
export class HeaderMenuItemComponent {
  @Input() item: MenuItem;

  get isSubMenu(): boolean {
    return this.item.items && this.item.items.length > 0;
  }
}
