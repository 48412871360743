import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {KeycloakService} from "keycloak-angular";

@Directive({
  selector: '[hasRole]'
})
export class HasRoleDirective {
  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private keycloakService: KeycloakService) { }

  @Input()
  set hasRole(role) {
    if(this.keycloakService.getUserRoles(true).indexOf(role) > -1) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
