<mat-card appearance="outlined">
  <mat-card-title>Patch {{ patch?.patchNr }}</mat-card-title>

  <mat-card-subtitle>{{ patch?.description }}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="contractNr">
          <th mat-header-cell *matHeaderCellDef>ContractNR</th>
          <td mat-cell *matCellDef="let element">{{ element.contractNr }}</td>
        </ng-container>

        <ng-container matColumnDef="contractName">
          <th mat-header-cell *matHeaderCellDef>Naam</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="patchLog">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td
            mat-cell
            *matCellDef="let element"
            [class]="
              element.patchLog
                ? element.patchLog.isCompleted
                  ? 'patched'
                  : 'notpatched'
                : ''
            "
          >
            {{ element.patchLog?.patchDate | date: "short" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              (click)="getLogsForContract(element.contractNr)"
              color="primary"
              mat-icon-button
              aria-label="importeren"
            >
              <mat-icon>refresh</mat-icon>
            </button>

            <button
              (click)="runPatchForContract(element.contractNr)"
              color="warn"
              mat-icon-button
              aria-label="Patch toepassen"
            >
              <mat-icon>system_update_alt</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="detail-row"
        ></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 15, 20, 25, 50]"
        pageSize="20"
        showFirstLastButtons
        aria-label="Selecteer"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button (click)="back()">Terug</button>
  </mat-card-actions>
</mat-card>
