<h2>Synchronisatie DUO scholen</h2>
<mat-card appearance="outlined">
  <ng-template #loading>
    <mat-card-content class="spinner">
      <table>
        <tr>
          <td class="spinner">
            <mat-spinner [diameter]="40"></mat-spinner>
          </td>
          <td class="text">
            <i
              >Bezig met synchroniseren. Dit kan even duren, een moment
              geduld....</i
            >
          </td>
        </tr>
      </table>
    </mat-card-content>
  </ng-template>

  <mat-card-content>
    <mat-tab-group dynamicHeight *ngIf="duoSync; else loading">
      <mat-tab label="Toegevoegd ({{ duoSync.added.length }}">
        <app-duo-sync-table [data]="duoSync.added"></app-duo-sync-table>
      </mat-tab>
      <mat-tab label="Aangepast ({{ duoSync.updated.length }})">
        <app-duo-sync-table [data]="duoSync.updated"></app-duo-sync-table>
      </mat-tab>
      <mat-tab label="Verwijderd ({{ duoSync.removed.length }})">
        <app-duo-sync-table [data]="duoSync.removed"></app-duo-sync-table>
      </mat-tab>
      <mat-tab
        label="Manual (DUO niet aanw.) ({{ duoSync.manualDeleted.length }})"
      >
        <app-duo-sync-table [data]="duoSync.manualDeleted"></app-duo-sync-table>
      </mat-tab>
      <mat-tab
        label="Dubbele BRIN-type combi's ({{ duoSync.doubleBrinType.length }})"
      >
        <app-duo-sync-table
          [data]="duoSync.doubleBrinType"
        ></app-duo-sync-table>
      </mat-tab>
    </mat-tab-group>

    <div class="mat-elevation-z0" *ngIf="false">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="brinNumber">
          <th mat-header-cell *matHeaderCellDef>Code</th>
          <td mat-cell *matCellDef="let element">{{ element.brinNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="locationName">
          <th mat-header-cell *matHeaderCellDef>Naam</th>
          <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
        </ng-container>

        <ng-container matColumnDef="alias">
          <th mat-header-cell *matHeaderCellDef>Alias</th>
          <td mat-cell *matCellDef="let element">{{ element.alias }}</td>
        </ng-container>

        <ng-container matColumnDef="schoolType">
          <th mat-header-cell *matHeaderCellDef>Schooltype</th>
          <td mat-cell *matCellDef="let element">{{ element.schoolType }}</td>
        </ng-container>

        <ng-container matColumnDef="fileType">
          <th mat-header-cell *matHeaderCellDef>Filetype</th>
          <td mat-cell *matCellDef="let element">{{ element.fileType }}</td>
        </ng-container>

        <ng-container matColumnDef="cityName">
          <th mat-header-cell *matHeaderCellDef>Plaats</th>
          <td mat-cell *matCellDef="let element">{{ element.cityName }}</td>
        </ng-container>

        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef>DUO Naam</th>
          <td mat-cell *matCellDef="let element">{{ element.locationName }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="detail-row"
        ></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 15, 20, 25]"
        pageSize="15"
        showFirstLastButtons
        aria-label="Selecteer"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <!--
    <button mat-raised-button color="primary" (click)="importAll()">
      <mat-icon>sync</mat-icon> Alle Bijwerken
    </button>
    <button mat-raised-button color="warn" (click)="processAll()">
      <mat-icon>save</mat-icon> Alle Opslaan
    </button>
    -->
  </mat-card-actions>
</mat-card>
