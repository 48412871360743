import {
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";

export class CustomValidators {
  static isNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const isNumber = /^[0-9]+$/.test(value);

      return isNumber ? null : { isNoNumber: true };
    };
  }

  static matchesPattern(patternToMatch: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const input = control.value;
      const regExPattern = new RegExp(`^${patternToMatch}$`);
      const isMatch = regExPattern.test(input);
      return isMatch ? null : { patternMismatch: true };
    };
  }
}
