import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BagAddress } from '../model/adresses';
import { DuoSchool } from '../model/duoSchool';
import { Postalcodelookup } from '../model/postalcodelookup';
import {DuoSync} from "../../../modules/databaseUpdates/duo-sync/duo-sync.component";

@Injectable()
export class DuoSchoolService {
  duoSchoolUrl = '/api/duo/';

  constructor(private http: HttpClient) {}

  getDuoSchools(): Observable<DuoSchool[]> {
    return this.http.get<DuoSchool[]>(this.duoSchoolUrl);
  }

  getDuoSchool(duoSchoolId: string): Observable<DuoSchool> {
    return this.http.get<DuoSchool>(this.duoSchoolUrl + duoSchoolId);
  }

  saveDuoSchool(duoSchool: DuoSchool): Observable<DuoSchool> {
    return this.http.put<DuoSchool>(
      this.duoSchoolUrl + duoSchool.id,
      duoSchool
    );
  }

  addDuoSchool(duoSchool: DuoSchool): Observable<DuoSchool> {
    return this.http.post<DuoSchool>(this.duoSchoolUrl, duoSchool);
  }

  applyPatch(): Observable<string> {
    return this.http.post<string>(this.duoSchoolUrl + 'patch', {});
  }

  syncSchools(): Observable<DuoSync> {
    return this.http.post<DuoSync>(this.duoSchoolUrl + 'sync', {});
  }

  deleteDuoSchool(duoSchool: DuoSchool): Observable<DuoSchool> {
    return this.http.delete<DuoSchool>(this.duoSchoolUrl + duoSchool.id);
  }

  getAddresses(postalCodeLookup: Postalcodelookup): Observable<BagAddress> {
    return this.http.post<BagAddress>(
      this.duoSchoolUrl + 'address/',
      postalCodeLookup
    );
  }
}
