<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      <strong>Contract: {{ contract.name }} - {{ contract.contractNr }}</strong>
    </mat-card-title>
    <mat-card-subtitle
      ><span *ngIf="contractId !== 'new'">
        ID: {{ contract.id }} - Laatst gewijzigd:
        {{ contract.modifiedAt | date }} - Aangemaakt op:
        {{ contract.createdAt | date }} - Geactiveerd op:
        {{ contract.activationDate | date }}</span
      ><br
    /></mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="contractForm">
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Naam" formControlName="name" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>ContractNr</mat-label>
            <input
              matInput
              placeholder="contractNr"
              formControlName="contractNr"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let type of contractTypes" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>DVO Nummer</mat-label>
            <input matInput placeholder="DVO Nummer" formControlName="dvo" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Startdatum</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="Startdatum"
              formControlName="contractStartDate"
            />
            <mat-hint>DD-MM-YYYY</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Hostnaam" formControlName="hostName" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <textarea
              matInput
              placeholder="Opmerkingen"
              formControlName="remarks"
              rows="4"
            ></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Database status</mat-label>
            <input
              matInput
              placeholder="Database"
              formControlName="dbStatusModifiedAt"
            />
            <mat-icon
              matSuffix
              color="{{
                contract.dbStatus === 'COMPLETED'
                  ? 'primary'
                  : contract.dbStatus === 'ABORTED'
                  ? 'warn'
                  : ''
              }}"
              >{{
                contract.dbStatus === "COMPLETED"
                  ? "sentiment_very_satisfied"
                  : contract.dbStatus === "ABORTED"
                  ? "sentiment_very_dissatisfied"
                  : "sentiment_neutral"
              }}</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Keycloak status</mat-label>
            <input
              matInput
              placeholder="Keycloak"
              formControlName="kcStatusModifiedAt"
            />
            <mat-icon
              matSuffix
              color="{{
                contract.kcStatus === 'COMPLETED'
                  ? 'primary'
                  : contract.kcStatus === 'ABORTED'
                  ? 'warn'
                  : ''
              }}"
              >{{
                contract.kcStatus === "COMPLETED"
                  ? "sentiment_very_satisfied"
                  : contract.kcStatus === "ABORTED"
                  ? "sentiment_very_dissatisfied"
                  : "sentiment_neutral"
              }}</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Zendesk status</mat-label>
            <input
              matInput
              placeholder="Zendesk"
              formControlName="supportStatusModifiedAt"
            />
            <mat-icon
              matSuffix
              color="{{
                contract.supportStatus === 'COMPLETED'
                  ? 'primary'
                  : contract.supportStatus === 'ABORTED'
                  ? 'warn'
                  : ''
              }}"
              >{{
                contract.supportStatus === "COMPLETED"
                  ? "sentiment_very_satisfied"
                  : contract.supportStatus === "ABORTED"
                  ? "sentiment_very_dissatisfied"
                  : "sentiment_neutral"
              }}</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Forecast</mat-label>
            <input matInput placeholder="10" formControlName="forecast" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-checkbox class="full-width" formControlName="isDemo">
            Is een demo omgeving
          </mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-checkbox class="full-width" formControlName="isActive">
            Is actief
          </mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-checkbox
            class="full-width"
            formControlName="isOnboarding"
            (click)="changeIsOnboarding()"
          >
            Is onboarding
          </mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-checkbox
            class="full-width"
            [disabled]="true"
            [checked]="contract.inProduction"
          >
            In productie
          </mat-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            class="full-width"
            *ngIf="this.contractForm.get('isOnboarding').value"
          >
            <mat-label>Aantal tegels</mat-label>
            <input
              matInput
              placeholder="Aantal tegels"
              formControlName="numberOfTiles"
            />
          </mat-form-field>
        </div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div>
      </div>
      <br />
      <mat-divider></mat-divider>
      <div class="row">
        <div class="col">
          <ng-container>
            <p>Kies een pool om te bewerken</p>
            <mat-chip-listbox>
              <mat-chip-option
                *ngFor="let option of contract.poolList"
                (click)="selectOption(option)"
              >
                {{ option.text }}
                <button
                  matChipRemove
                  matTooltip="Verwijder deze optie"
                  (click)="deleteOption(option)"
                  *ngIf="contract.poolList?.length > 0"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-option>
            </mat-chip-listbox>
            <br />
            <mat-form-field class="full-width">
              <mat-label>Poolnaam</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="selectedOption.text"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="changeOption()"
                placeholder="Kies een pool of type hier een nieuwe naam in"
              />
              <button
                *ngIf="showAddOption"
                matSuffix
                mat-icon-button
                matTooltip="Toevoegen"
                (click)="addOption()"
              >
                <mat-icon>add_circle</mat-icon>
              </button>
              <button
                *ngIf="showClearOption"
                matSuffix
                mat-icon-button
                matTooltip="Stop wijzigen van deze pool"
                (click)="clearOption()"
              >
                <mat-icon>close</mat-icon>
              </button>
              <mat-hint
                >Wijzig hier het de naam van de pool of voeg een nieuwe pool
                toe</mat-hint
              >
            </mat-form-field>
          </ng-container>
        </div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div>
      </div>
      <br />
      <mat-divider></mat-divider>
      <div class="row">
        <app-contact
          [contactList]="contract.contacts"
          [contractID]="contractId"
          class="col"
        ></app-contact>

        <app-address
          [addressList]="contract.addresses"
          [contractID]="contractId"
          class="col"
        ></app-address>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button (click)="back()">
      <mat-icon>cancel</mat-icon> Annuleren
    </button>
    <button mat-raised-button color="accent" (click)="goTo($event, 'ap')">
      <mat-icon>school</mat-icon> Aanmeldportaal
    </button>
    <button mat-raised-button color="primary" (click)="goTo($event, 'bp')">
      <mat-icon>website</mat-icon> Beheerportaal
    </button>
    <button mat-raised-button color="warn" (click)="delete()">
      <mat-icon>delete</mat-icon> Verwijderen
    </button>
    <button mat-raised-button [matMenuTriggerFor]="menu" color="accent">
      <mat-icon>more_vert</mat-icon>
      Opties
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        [disabled]="contract.dbStatus === 'COMPLETED'"
        (click)="checkDatabase()"
      >
        <mat-icon>reset</mat-icon>
        <span>Aanmaken van Database</span>
      </button>
      <button
        mat-menu-item
        [disabled]="contract.dbStatus !== 'COMPLETED'"
        (click)="checkDatabase()"
      >
        <mat-icon>reset</mat-icon>
        <span>Resetten van Database</span>
      </button>
      <button mat-menu-item (click)="readyForProduction()">
        <mat-icon>reset</mat-icon>
        <span>Productie Gereed Maken</span>
      </button>
      <button
        mat-menu-item
        [disabled]="
          contract.dbStatus !== 'COMPLETED' && contract.kcStatus !== 'COMPLETED'
        "
        (click)="checkRoles()"
      >
        <mat-icon>user</mat-icon>
        <span>Controleer rollen</span>
      </button>
      <button mat-menu-item (click)="linkSchoolToLasInfo()">
        <mat-icon>reset</mat-icon>
        <span>LAS koppelen</span>
      </button>
      <button
        mat-menu-item
        [disabled]="contract.kcStatus === 'COMPLETED'"
        (click)="checkKeycloakRealms()"
      >
        <mat-icon>notifications_off</mat-icon>
        <span>Aanmaken van KeyCloak Realms</span>
      </button>
      <button
        mat-menu-item
        [disabled]="contract.contractNr === null"
        (click)="getKeycloakJson()"
      >
        <mat-icon>notifications_off</mat-icon>
        <span>Download KeyCloak Config</span>
      </button>
      <button
        mat-menu-item
        [disabled]="contract.supportStatus === 'COMPLETED'"
        (click)="checkSupport()"
      >
        <mat-icon>notifications_off</mat-icon>
        <span>Aanmaken van Zendesk organisatie</span>
      </button>
      <button
        mat-menu-item
        [disabled]="contract.supportStatus !== 'COMPLETED'"
        (click)="removeSupport()"
      >
        <mat-icon>delete</mat-icon>
        <span>Verwijderen van Zendesk organisatie</span>
      </button>

      <button
        mat-menu-item
        *ngIf="this.contractForm.get('isOnboarding').value"
        (click)="copy(contract)"
        matTooltip="Kopieer tekst"
      >
        <mat-icon>content_copy</mat-icon>
        <span>Onboarding link kopieren</span>
        <mat-icon *ngIf="isCopied" color="accent">check</mat-icon>
      </button>
    </mat-menu>

    <button mat-raised-button color="primary" (click)="save()">
      <mat-icon>save</mat-icon> Opslaan
    </button>
  </mat-card-actions>
</mat-card>
