<div class="tablecontent">
  <div
    class="mat-elevation-z0 nodata"
    *ngIf="!dataSource || !dataSource.data || dataSource.data.length == 0"
  >
    <i>Geen data aanwezig.</i>
  </div>
  <div
    class="mat-elevation-z1"
    *ngIf="dataSource.data && dataSource.data.length > 0"
  >
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="brinNumber">
        <th mat-header-cell *matHeaderCellDef>Code</th>
        <td mat-cell *matCellDef="let element">{{ element.brinNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="locationName">
        <th mat-header-cell *matHeaderCellDef>Naam</th>
        <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
      </ng-container>

      <ng-container matColumnDef="alias">
        <th mat-header-cell *matHeaderCellDef>Alias</th>
        <td mat-cell *matCellDef="let element">{{ element.alias }}</td>
      </ng-container>

      <ng-container matColumnDef="schoolType">
        <th mat-header-cell *matHeaderCellDef>Schooltype</th>
        <td mat-cell *matCellDef="let element">{{ element.schoolType }}</td>
      </ng-container>

      <ng-container matColumnDef="fileType">
        <th mat-header-cell *matHeaderCellDef>Filetype</th>
        <td mat-cell *matCellDef="let element">{{ element.fileType }}</td>
      </ng-container>

      <ng-container matColumnDef="cityName">
        <th mat-header-cell *matHeaderCellDef>Plaats</th>
        <td mat-cell *matCellDef="let element">{{ element.cityName }}</td>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef>DUO Naam</th>
        <td mat-cell *matCellDef="let element">{{ element.locationName }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="detail-row"
      ></tr>
    </table>
  </div>
</div>
