<h2>Update stam tabellen</h2>
<mat-card appearance="outlined">
  <mat-card-content>
    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dbTables" aria-label="stamtabellen">
        <ng-container matColumnDef="tableName">
          <th mat-header-cell *matHeaderCellDef>Tabel</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="tableCurrent">
          <th mat-header-cell *matHeaderCellDef># Current</th>
          <td mat-cell *matCellDef="let element">{{ element.nrCurrent }}</td>
        </ng-container>

        <ng-container matColumnDef="tableStaging">
          <th mat-header-cell *matHeaderCellDef># Staging</th>
          <td mat-cell *matCellDef="let element">{{ element.nrNew }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actie</th>
          <td mat-cell *matCellDef="let element">
            <button
              (click)="import(element.name)"
              color="primary"
              mat-icon-button
              aria-label="importeren"
            >
              <mat-icon>sync</mat-icon>
            </button>

            <button
              *ngIf="element.name !== 'schools'"
              (click)="process(element.name)"
              color="warn"
              mat-icon-button
              aria-label="Verwerken"
            >
              <mat-icon>save</mat-icon>
            </button>
            <button
              *ngIf="element.name === 'schools'"
              (click)="goToSchools(element.name)"
              color="warn"
              mat-icon-button
              aria-label="Verwerken"
            >
              <mat-icon>input</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button color="primary" (click)="importAll()">
      <mat-icon>sync</mat-icon> Alle Bijwerken
    </button>
    <button mat-raised-button color="warn" (click)="processAll()">
      <mat-icon>save</mat-icon> Alle Opslaan
    </button>
  </mat-card-actions>
</mat-card>
