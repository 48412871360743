import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { ContactPerson } from 'src/app/shared/components/model/contract';
import { ContractService } from 'src/app/shared/components/services/contract.service';

import { DialogService } from '../../../../shared/components/services/dialog.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent {
  @ViewChild('contactTpl') addContactTpl: TemplateRef<any>;
  @Input() contactList: ContactPerson[] = [];
  @Input() contractID: string = '';

  formGroup: FormGroup = this.fb.group({
    id: [null],
    type: ['TECHNICAL', Validators.required],
    firstName: [null, Validators.required],
    middleName: [null],
    lastName: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    remarks: [null],
  });

  displayColsContacts: string[] = [
    'firstName',
    'middleName',
    'lastName',
    'email',
    'actions',
  ];

  constructor(
    private fb: FormBuilder,
    private contractService: ContractService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<boolean>,
    public dialogService: DialogService,
  ) {}

  goToContact(contact: ContactPerson): void {
    this.formGroup.reset();
    this.formGroup.patchValue(contact);
    this.dialogRef = this.dialog.open(this.addContactTpl, {
      minWidth: '900px',
      maxWidth: '65vw',
      minHeight: '35vh',
      maxHeight: '80vh',
    });
  }

  save(): void {
    if (!this.formGroup.valid) return;

    const value = this.formGroup.value;
    const contact: ContactPerson = {
      id: value.id,
      type: value.type,
      firstName: value.firstName,
      middleName: value.middleName,
      lastName: value.lastName,
      email: value.email,
      remarks: value.remarks,
    };

    if (!!contact.id) {
      this.update(contact);
    } else {
      this.create(contact);
    }
  }

  update(contact: ContactPerson): void {
    this.contractService
      .updateContactPerson(this.contractID, contact)
      .pipe(take(1))
      .subscribe((contract) => {
        this.contactList = contract.contacts;
        this.dialogRef.close();
        this.showToast(contact.lastName + ' is aangepast');
      });
  }

  create(contact: ContactPerson): void {
    this.contractService
      .addContactPerson(this.contractID, contact)
      .pipe(take(1))
      .subscribe((contract) => {
        this.contactList = contract.contacts;
        this.dialogRef.close();
        this.showToast(contact.lastName + ' is toegevoegd als contactpersoon.');
      });
  }

  deleteContact(contact: ContactPerson): void {
    this.dialogService
      .confirm(
        'U staat op het punt om ' +
          contact.lastName +
          ' als contact te verwijderen, Weet u zeker dat u door wilt gaan?',
      )
      .pipe(take(1))
      .subscribe((_) => {
        this.contractService
          .deleteContactPerson(this.contractID, contact.id)
          .pipe(take(1))
          .subscribe((contract) => {
            this.contactList = contract.contacts;
            this.showToast(
              contact.lastName + ' is verwijderd als contactpersoon.',
            );
          });
      });
  }

  /**
   * Maak de gebruiker als beheerder aan in de beheer realm
   *
   * @param contact
   */
  setKcUser(contact: ContactPerson): void {
    this.contractService
      .addBackendUser(this.contractID, contact.id)
      .pipe(take(1))
      .subscribe((res) => {
        this.showToast('toegevoegd als beheerder');
      });
  }

  setSupportUser(contact: ContactPerson): void {
    this.contractService
      .addSupportUser(this.contractID, contact.id)
      .pipe(take(1))
      .subscribe((res) => {
        this.showToast(contact.lastName + ' is toegevoegd als supportuser');
      });
  }

  removeSupportUser(contact: ContactPerson): void {
    this.contractService
      .removeSupportUser(this.contractID, contact.id)
      .pipe(take(1))
      .subscribe((res) => {
        this.showToast(contact.lastName + 'verwijderd als supportuser');
      });
  }

  public showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten');
  }

  addLeerlinqSupport() {
    this.contractService
      .impersonate(this.contractID)
      .pipe(take(1))
      .subscribe((url) => {
        this.showToast(
          'Leerlinq Support is toegevoegd als user voor dit contract.',
        );
        window.open(url.url, "_blank");
      });
  }
}
