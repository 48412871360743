import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BagDTO } from '../model/adresses';
import { PagingDTO } from '../model/paging';
import { CountryCode, NationalityCode } from '../model/bagData';

@Injectable()
export class BagDataService {
  countryCodeUrl = '/api/country/';
  nationalityCodeUrl = '/api/nationality/';
  bagAddressUrl = '/api/bag/';

  constructor(private http: HttpClient) {}

  getCountryCodes(): Observable<CountryCode[]> {
    return this.http.get<CountryCode[]>(this.countryCodeUrl);
  }
  getNationalityCodes(): Observable<NationalityCode[]> {
    return this.http.get<NationalityCode[]>(this.nationalityCodeUrl);
  }
  getBagAddresses(page: PagingDTO, filter: string): Observable<BagDTO> {
    return this.http.get<BagDTO>(this.bagAddressUrl, {
      params: {
        page: page.currentPage,
        size: page.pageSize,
        filter: filter,
      },
    });
  }
}
