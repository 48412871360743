<mat-toolbar class="mat-elevation-z3">
  <button type="button" aria-label="Toggle sidenav" mat-button>
    <img src="assets/images/logo.svg" width="200" alt="Leerlinq" />
  </button>

  <ng-container *isAuthenticated>
    <ng-template ngFor let-item [ngForOf]="menuItems">
      <app-header-menu-item [item]="item"></app-header-menu-item>
    </ng-template>
    <span class="topbar-spacer topbar-spacer-small"></span>

    <button mat-button aria-label="Submenu account" [matMenuTriggerFor]="menu">
      <mat-icon>account_box</mat-icon>
      <span> {{ profile?.firstName }} {{ profile?.lastName }}</span>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        class="example-icon"
        aria-label="Uitloggen"
        (click)="logout()"
      >
        <mat-icon>exit_to_app</mat-icon>
        <span>Uitloggen</span>
      </button>
    </mat-menu>
  </ng-container>
</mat-toolbar>
