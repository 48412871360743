import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { BagAddress } from '../../shared/components/model/adresses';
import { PagingDTO } from '../../shared/components/model/paging';

import { BagDataService } from '../../shared/components/services/bagData.service';

@Component({
  selector: 'app-bag',
  templateUrl: './bagAddress.component.html',
  styleUrls: ['./bagAddress.component.css'],
})
export class BagAddressComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  pageEvent: PageEvent;
  dataSource = new MatTableDataSource<BagAddress>();
  displayedColumns: string[] = [
    'postalcode',
    'streetname',
    'cityname',
    'provincename',
    'minHouseNumber',
    'maxHouseNumber',
  ];

  pagingDTO: PagingDTO = {
    totalItems: 0,
    currentPage: 0,
    pageSize: 20,
    limit: 0,
  } as PagingDTO;
  filtertext = '';

  constructor(
    private router: Router,
    private bagDataService: BagDataService,
  ) {}

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Aantal per pagina: ';
    this.getBagAddresses(this.pagingDTO);
  }

  onPageChange(e: any): PageEvent {
    this.pagingDTO.currentPage = e.pageIndex;
    this.getBagAddresses(this.pagingDTO);
    return e;
  }

  getBagAddresses(pageInfo: PagingDTO): void {
    this.bagDataService
      .getBagAddresses(pageInfo, this.filtertext)
      .subscribe((dto) => {
        this.pagingDTO.totalItems = dto.totalItems;
        this.dataSource.data = dto.addresses;
        this.paginator._intl.itemsPerPageLabel = 'Aantal per pagina: ';
      });
  }

  filterDatatable(event): void {
    this.filtertext = event.target.value;
    this.pagingDTO.currentPage = 0;
    this.getBagAddresses(this.pagingDTO);
  }
}
