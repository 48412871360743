import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {Patch} from "../model/patch";
import {PatchLog} from "../model/patchLog";

@Injectable()
export class PatchService {
  patchUrl = "/api/patch/";

  constructor(private http: HttpClient) {}

  getPatches() {
    return this.http.get<Patch[]>(this.patchUrl);
  }

  getPatch(patchId: string): Observable<Patch> {
    return this.http.get<Patch>(this.patchUrl + patchId);
  }

  getPatchLogs(patchId: string): Observable<PatchLog[]> {
    return this.http.get<PatchLog[]>(this.patchUrl + patchId + "/logs");
  }

  getPatchLogsForContract(patchId: string, contractNr: number): Observable<PatchLog[]> {
    return this.http.get<PatchLog[]>(this.patchUrl + patchId + "/contract/" + contractNr + "/logs");
  }

  runPatch(patch: Patch, contractNr: number): Observable<Patch> {
    return this.http.post<Patch>(this.patchUrl + patch.id + "/contract/" + contractNr, null);
  }

  checkPatches() {
    return this.http.post<boolean>(
      this.patchUrl + "check",
      null
    );
  }

}
