import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DuoSchool} from "../../../../shared/components/model/duoSchool";

@Component({
  selector: 'app-duo-sync-table',
  templateUrl: './duo-sync-table.component.html',
  styleUrls: ['./duo-sync-table.component.css'],
})
export class DuoSyncTableComponent implements OnInit {
  @Input() data: DuoSchool[];
  @Input() header: string;
  dataSource = new MatTableDataSource<DuoSchool>()

  constructor() {

  }
  displayedColumns: string[] = [
    'brinNumber',
    'displayName',
    'alias',
    'schoolType',
    'fileType',
    'cityName',
    'locationName',
  ];

  ngOnInit(): void {
    this.dataSource.data = this.data;
  }
}
