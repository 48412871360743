import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SignUp } from '../model/signup';

@Injectable()
export class SignupService {
  private signUpUrl = 'api/signup/';

  constructor(private http: HttpClient) {}

  getContractNrBySignUpId(id: string) {
    // get call to api/signup/ID/contractnr/
    return this.http.get<string>(this.signUpUrl + id + '/contractnr/');
  }

  getSignUp(id: string) {
    // get call to api/signup/ID
    return this.http.get<SignUp>(this.signUpUrl + id, {
      observe: 'response',
    });
  }

  updateSignUp(signUp: SignUp, auditMessage: string) {
    // post call to api/signup/
    return this.http.post(this.signUpUrl, {
      signUp,
      auditMessage,
    });
  }

  deleteLoggingItemBySignUpId(
    id: string,
    auditMessage: string,
  ): Observable<boolean> {
    // delete call to api/signup/ID/logging/
    return this.http.delete<boolean>(
      this.signUpUrl.concat(id).concat('/logging/'),
      { body: auditMessage },
    );
  }
}
