import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { Patch } from '../../shared/components/model/patch';
import { PatchService } from '../../shared/components/services/patch.service';

@Component({
  selector: 'app-patch',
  templateUrl: './patch.component.html',
  styleUrls: ['./patch.component.css'],
})
export class PatchComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<Patch>();
  displayedColumns: string[] = ['patchNr', 'description', 'isCompleted'];

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  constructor(private router: Router, private patchService: PatchService) {
    this.getPatches();
  }

  checkForPatches(): void {
    this.patchService.checkPatches().subscribe((res) => {
      this.getPatches();
    });
  }

  getPatches(): void {
    this.patchService.getPatches().subscribe((patches) => {
      this.dataSource.data = patches;
    });
  }

  goToPatch(patch: Patch): void {
    this.router.navigate(['/patch', patch.id]);
  }
}
