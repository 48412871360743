import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Databrickstable } from '../../shared/components/model/databrickstable';
import { UpdatedbService } from '../../shared/components/services/updatedb.service';

@Component({
  selector: 'app-updatedb',
  templateUrl: './updatedb.component.html',
  styleUrls: ['./updatedb.component.css'],
})
export class UpdatedbComponent {
  displayedColumns: string[] = [
    'tableName',
    'tableCurrent',
    'tableStaging',
    'actions',
  ];

  public dbTables: Databrickstable[];
  public tables: Databrickstable[] = [];

  constructor(
    private dbService: UpdatedbService,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) {
    this.getTables();
  }

  public showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten');
  }

  getTables(): void {
    this.dbService.getTables().subscribe((tables) => {
      this.dbTables = tables;
      this.fillNumberOfRecords();
    });
  }

  fillNumberOfRecords(): void {
    for (var i = 0; i < this.dbTables.length; i++) {
      let table: Databrickstable = new Databrickstable();
      table.name = this.dbTables[i].name;
      table.nrCurrent = this.dbTables[i].nrCurrent;
      table.nrNew = this.dbTables[i].nrNew;
      this.tables.push(table);
    }
  }

  import(table: string): void {
    this.showToast('Tabel ' + table + ' wordt bijgewerkt.');
    this.dbService.importTable(table).subscribe((_) => {
      this.tables = [];
      this.fillNumberOfRecords();
    });
  }

  process(table: string): void {
    this.showToast('Tabel ' + table + ' wordt opgeslagen.');
    this.dbService.processTable(table).subscribe((_) => {
      this.tables = [];
      this.fillNumberOfRecords();
    });
  }

  goToSchools(table: string): void {
    this.router.navigate(['/updatedb/duosync']);
  }

  importAll(): void {
    this.showToast('Alle tabellen worden bijgewerkt.');
    this.dbService.importAllTables().subscribe((_) => {
      this.tables = [];
      this.fillNumberOfRecords();
    });
  }

  processAll(): void {
    this.showToast('Alle tabellen worden opgeslagen.');
    this.dbService.processAllTables().subscribe((_) => {
      this.tables = [];
      this.fillNumberOfRecords();
    });
  }
}
