import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import saveAs from 'file-saver';

import { PagingDTO } from '../../shared/components/model/paging';
import { Report } from '../../shared/components/model/report';
import { ReportService } from '../../shared/components/services/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  providers: [DatePipe],
})
export class ReportComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['name', 'description', 'actions'];

  pagingDTO: PagingDTO = {
    totalItems: 0,
    currentPage: 0,
    limit: 0,
    pageSize: 20,
    totalPages: 0,
    orderColumn: 'name',
    orderDir: 'desc',
  } as PagingDTO;
  pageEvent: PageEvent;

  constructor(
    private datePipe: DatePipe,
    private reportService: ReportService,
  ) {}

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Aantal per pagina';
    this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe((sortInfo) => {
      this.onSort(sortInfo);
    });
    this.getReports();
  }

  getReports() {
    this.reportService.getReports(this.pagingDTO).subscribe((dto) => {
      this.pagingDTO.totalItems = dto.totalItems;
      this.pagingDTO.currentPage = dto.currentPage;
      this.dataSource.data = dto.reports;
    });
  }

  onPageChange(e: PageEvent) {
    this.pagingDTO.currentPage = e.pageIndex;
    this.pagingDTO.pageSize = e.pageSize;
    this.getReports();
    return e;
  }

  onSort(sortInfo): void {
    this.pagingDTO.orderColumn = sortInfo.active;
    this.pagingDTO.orderDir = sortInfo.direction;

    this.getReports();
  }

  getReport(report: Report): void {
    const accept =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    this.reportService.getReport(report.reportId, accept).subscribe((data) => {
      const blob = new Blob([data], { type: accept });
      const date: string = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
      saveAs(blob, report.name + ' ' + date);
    });
  }
}
