import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { DuoSchool } from '../../shared/components/model/duoSchool';
import { DuoSchoolService } from '../../shared/components/services/duoSchool.service';
import { DuoSchoolEditComponent } from './duo-edit/duoSchool-edit.component';

@Component({
  selector: 'app-duo',
  templateUrl: './duoSchool.component.html',
  styleUrls: ['./duoSchool.component.css'],
})
export class DuoSchoolComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<DuoSchool>();
  displayedColumns: string[] = [
    'brinNumber',
    'displayName',
    'locationName',
    'alias',
    'schoolType',
    'fileType',
    'cityName',
  ];

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private router: Router,
    private duoService: DuoSchoolService,
    private readonly dialog: MatDialog,
  ) {
    this.getDuoSchools();
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getDuoSchools(): void {
    this.duoService
      .getDuoSchools()
      .pipe(take(1))
      .subscribe((duoSchools) => {
        this.dataSource.data = duoSchools;
      });
  }

  goToSchool(school: DuoSchool): void {
    const dialogConfig = {
      minWidth: '900px',
      maxWidth: '65vw',
      minHeight: '35vh',
      maxHeight: '80vh',
      data: school?.id,
    };

    const dialogRef = this.dialog.open(DuoSchoolEditComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.getDuoSchools();
      });
  }
}
