<ng-container *ngIf="!isSubMenu; else submenuItem">
  <ng-container *hasRole="item.roles">
    <button
      mat-button
      aria-label="Ga naar pagina {{ item.label }}"
      [routerLink]="item.path"
      routerLinkActive="mat-primary"
    >
      <mat-icon>{{ item.icon }}</mat-icon> <span>{{ item.label }}</span>
    </button>
  </ng-container>
</ng-container>

<ng-template #submenuItem>
  <ng-container *hasRole="item.roles">
    <button
      mat-button
      aria-label="Submenu {{ item.label }}"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>{{ item.icon }}</mat-icon> <span>{{ item.label }}</span>
    </button>
    <mat-menu #menu="matMenu">
      <ng-template ngFor let-subitem [ngForOf]="item.items">
        <button
          *hasRole="subitem.roles"
          [routerLink]="subitem.path"
          routerLinkActive="mat-primary"
          mat-menu-item
          aria-label="Ga naar pagina {{ subitem.label }}"
        >
          <mat-icon>{{ subitem.icon }}</mat-icon>
          <span>{{ subitem.label }}</span>
        </button>
      </ng-template>
    </mat-menu>
  </ng-container>
</ng-template>
