<h2>Addressen</h2>
<div class="mat-elevation-z1">
  <table mat-table [dataSource]="addressList">
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>Voornaam</th>
      <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="middleName">
      <th mat-header-cell *matHeaderCellDef>Tussenvoegsel</th>
      <td mat-cell *matCellDef="let element">{{ element.middleName }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>Achternaam</th>
      <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>e-mail</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button><mat-icon>more_vert</mat-icon></button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayColsContacts"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayColsContacts"
      class="detail-row"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Geen data om te tonen.</td>
    </tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[5, 10]"
    pageSize="5"
    showFirstLastButtons
    aria-label="Selecteer contactpersoon"
  >
  </mat-paginator>
</div>
<div class="row" align="end" style="margin-top: 5px">
  <div class="col">
    <button mat-raised-button color="accent" [disabled]="contractID === 'new'">
      Toevoegen
    </button>
  </div>
</div>
