import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Announcement, AnnouncementDTO } from '../model/announcement';
import { PagingDTO } from '../model/paging';

@Injectable()
export class AnnouncementService {
  private announcementUrl: string = '/api/announcement/';

  constructor(private http: HttpClient) {}

  getAnnouncementsByFilter(filter: PagingDTO): Observable<AnnouncementDTO> {
    return this.http.post<AnnouncementDTO>(
      this.announcementUrl + 'filter',
      filter,
    );
  }

  getAnnouncementById(id: string): Observable<HttpResponse<Announcement>> {
    return this.http.get<Announcement>(this.announcementUrl + id, {
      observe: 'response',
    });
  }

  saveAnnouncement(announcement: Announcement) {
    return this.http.post(this.announcementUrl, announcement);
  }

  deleteAnnouncementById(id: string) {
    return this.http.delete(this.announcementUrl + id);
  }
}
