import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';

import { LasInfo } from '../../../../shared/components/model/las-info';
import {
  SchoolConfigurationDTO,
  SchoolConfigurationLasUpdate,
} from '../../../../shared/components/model/school-configuration';
import { ContractService } from '../../../../shared/components/services/contract.service';

@Component({
  selector: 'app-connect-las-info-dialog',
  templateUrl: './connect-las-info-dialog.component.html',
  styleUrls: ['./connect-las-info-dialog.component.css'],
})
export class ConnectLasInfoDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConnectLasInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contractService: ContractService,
    private _snackBar: MatSnackBar,
  ) {}

  connectedSchools: Map<string, SchoolConfigurationDTO[]> = new Map();
  schoolConfigurations: Map<string, SchoolConfigurationDTO[]> = new Map();
  schoolYears: string[] = [];
  lasInfo: LasInfo[] = [];
  uniqueLasIdsMap: Map<string, LasInfo>;
  lasUpdate: SchoolConfigurationLasUpdate;
  selectedLasIds: string[];
  selectedLasLocationIds: string[];

  ngOnInit() {
    this.uniqueLasIdsMap = new Map<string, LasInfo>();
    this.lasUpdate = {
      schoolConfigurationId: '',
      lasId: '',
      lasLocationId: '',
      contractId: this.data.contractId,
    };
    this.selectedLasIds = [];
    this.selectedLasLocationIds = [];
    this.getLasInfo();
    this.getSchoolConfigurations();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  showToast(message: string): void {
    this._snackBar.open(message, 'Sluiten');
  }

  getLasInfo(): void {
    this.contractService
      .getLasInfo()
      .pipe(take(1))
      .subscribe((lasInfo) => {
        this.lasInfo = lasInfo;
        this.setLasTypeInName();
        this.lasInfo.forEach((las) => {
          if (!this.uniqueLasIdsMap.has(las.lasId)) {
            this.uniqueLasIdsMap.set(las.lasId, las);
          }
        });
        this.uniqueLasIdsMap = this.sortLasNames();
      });
  }

  setLasTypeInName(): void {
    this.lasInfo.forEach((las) => {
      if (las.lasType === 'Magister') {
        las.lasName = las.lasName + ' (M)';
      } else {
        las.lasName = las.lasName + ' (S)';
      }
    });
  }

  sortLasNames(): any {
    return new Map(
      [...this.uniqueLasIdsMap.entries()].sort((a, b) => {
        const lasA = a[1];
        const lasB = b[1];
        if (lasA.lasName === lasB.lasName) return 0;
        return lasA.lasName > lasB.lasName ? 1 : -1;
      }),
    );
  }

  getUniqueLasIdsMapValues(): LasInfo[] {
    return Array.from(this.uniqueLasIdsMap.values());
  }

  getUniqueLasIdsMapKeys(): string[] {
    return Array.from(this.uniqueLasIdsMap.keys());
  }

  sortByLasLocationName(): LasInfo[] {
    return this.lasInfo.sort((a, b) =>
      a.lasLocationName > b.lasLocationName
        ? 1
        : b.lasLocationName > a.lasLocationName
        ? -1
        : 0,
    );
  }

  getSchoolConfigurations(): void {
    this.contractService
      .getAllSchoolConfigurations(this.data.contractId)
      .pipe(take(1))
      .subscribe((schoolConfigurations) => {
        this.schoolYears = this.extractSchoolYears(schoolConfigurations);
        this.groupByLasIdPresent(schoolConfigurations);
      });
  }

  groupByLasIdPresent(schoolConfigArray: SchoolConfigurationDTO[]): void {
    const newConnectedSchools = [];
    const newSchoolConfigurations = [];
    // Divide school configurations into already linked schools and unlinked schools based on presence of lasId
    for (const schoolConfig of schoolConfigArray) {
      if (schoolConfig.lasId !== null && schoolConfig.lasId !== undefined) {
        newConnectedSchools.push(schoolConfig);
      } else {
        newSchoolConfigurations.push(schoolConfig);
      }
    }
    // Sort alphabetically
    newConnectedSchools.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
    );
    newSchoolConfigurations.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
    );
    // Divide by school year for tab implementation
    this.connectedSchools = this.mapBySchoolYear(newConnectedSchools);
    this.schoolConfigurations = this.mapBySchoolYear(newSchoolConfigurations);
  }

  mapBySchoolYear(schoolConfigArray: SchoolConfigurationDTO[]) {
    // Map with array of school configurations (value) per schoolyear (key)
    const schoolYearMap: Map<string, SchoolConfigurationDTO[]> = new Map();
    for (let school of schoolConfigArray) {
      const schoolYearArray =
        schoolYearMap.get(school.schoolYear) === undefined
          ? []
          : schoolYearMap.get(school.schoolYear);
      schoolYearArray.push(school);
      schoolYearMap.set(school.schoolYear, schoolYearArray);
    }
    return schoolYearMap;
  }

  getLasNameByLasId(lasId: string): string {
    if (
      this.uniqueLasIdsMap.get(lasId) !== undefined &&
      this.uniqueLasIdsMap.get(lasId) !== null &&
      this.uniqueLasIdsMap.get(lasId).lasName !== undefined &&
      this.uniqueLasIdsMap.get(lasId).lasName !== null
    ) {
      return this.uniqueLasIdsMap.get(lasId).lasName;
    }
    return 'Onbekend';
  }

  getLasLocationNameByLasLocationId(lasLocationId: string): string {
    if (this.lasInfo !== undefined && this.lasInfo !== null) {
      var result = this.lasInfo.filter((obj) => {
        return obj.lasLocationId === lasLocationId;
      });
      if (result.length > 0 && result[0].lasLocationName !== undefined) {
        return result[0].lasLocationName;
      }
    }
    return 'Onbekend';
  }

  updateDbLasInfo(): void {
    this.contractService
      .updateDbLasInfo(this.data.contractId)
      .pipe(take(1))
      .subscribe((_) => {
        this.showToast('LasInfo wordt geupdate. Open dit menu later opnieuw.');
        this.getLasInfo();
      });
  }

  updateSchoolConfigurationLasInfo(schoolYear: string, index: number): void {
    this.lasUpdate.schoolConfigurationId =
      this.schoolConfigurations.get(schoolYear)[index].id;
    this.lasUpdate.lasId = this.selectedLasIds[index];
    this.lasUpdate.lasLocationId = this.selectedLasLocationIds[index];
    this.contractService
      .updateSchoolConfigurationLasInfo(this.lasUpdate)
      .pipe(take(1))
      .subscribe((_) => {
        this.showToast('LasInfo is gekoppeld.');
        this.selectedLasIds = [];
        this.selectedLasLocationIds = [];
        this.getSchoolConfigurations();
      });
  }

  emptySchoolConfigurationLasInfo(schoolYear: string, index: number): void {
    this.lasUpdate.schoolConfigurationId =
      this.connectedSchools.get(schoolYear)[index].id;
    this.lasUpdate.lasId = null;
    this.lasUpdate.lasLocationId = null;
    this.contractService
      .updateSchoolConfigurationLasInfo(this.lasUpdate)
      .pipe(take(1))
      .subscribe((_) => {
        this.showToast('LasInfo is ontkoppeld.');
        this.selectedLasIds = [];
        this.selectedLasLocationIds = [];
        this.getSchoolConfigurations();
      });
  }

  resetSelectedIds() {
    this.selectedLasIds = [];
    this.selectedLasLocationIds = [];
  }

  extractSchoolYears(schoolConfigurations: SchoolConfigurationDTO[]): string[] {
    return [
      ...new Set(schoolConfigurations.map((school) => school.schoolYear)),
    ];
  }
}
