import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { EventService } from '../../shared/components/services/event.service';

@Component({
  selector: 'app-contract',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
})
export class EventComponent {
  eventForm: FormGroup = this.fb.group({
    action: [null, Validators.required],
    actionType: [null, Validators.required],
    contractNr: [null, Validators.required],
    identifier: [null, Validators.required],
    queue: [null, Validators.required],
    msg: [null],
  });
  constructor(
    private eventService: EventService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  send(): void {
    if (this.eventForm.valid) {
      this.eventService
        .sendEvent(this.eventForm.getRawValue())
        .pipe(take(1))
        .subscribe();
    }
  }

  reset(): void {
    this.eventForm.reset();
  }
}
