<h2>Rapportages</h2>

<mat-card appearance="outlined">
  <mat-card-content>
    <div class="mat-elevation-z0">
      <table
        mat-table
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        aria-label="Selecteer"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Naam</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Omschrijving</th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Uitvoeren</th>
          <td mat-cell *matCellDef="let element">
            <button
              (click)="getReport(element)"
              color="primary"
              mat-icon-button
              aria-label="uitvoeren"
            >
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="non-clickable-row"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            Geen rapportages gevonden
          </td>
        </tr>
      </table>
      <mat-paginator
        [length]="pagingDTO.totalItems"
        [pageSize]="pagingDTO.pageSize"
        [pageIndex]="pagingDTO.currentPage"
        [pageSizeOptions]="[10, 20, 50, 100]"
        (page)="pageEvent = onPageChange($event)"
        showFirstLastButtons
        aria-label="Selecteer"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
