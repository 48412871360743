import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Filter } from '../model/filter';
import { SignupStatsDTO } from '../model/signup-stats';

@Injectable()
export class SignupStatsService {
  private auditUrl = 'api/stats/';

  constructor(private http: HttpClient) {}

  getSignupStatsByFilter(filter: Filter): Observable<SignupStatsDTO> {
    return this.http.post<SignupStatsDTO>(this.auditUrl, filter);
  }

  getSignupStatsBySignupID(filter: Filter): Observable<SignupStatsDTO> {
    return this.http.post<SignupStatsDTO>(
      this.auditUrl.concat(filter.searchFilterText),
      filter,
    );
  }

  deleteSignupStat(signUpId: string, contractNr: string, auditMessage: string) {
    return this.http.post<boolean>(
      this.auditUrl.concat('delete'),
      {
        id: signUpId,
        contractNr: contractNr,
        auditMessage: auditMessage,
      },
      {
        observe: 'response',
      },
    );
  }
}
