import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Event } from '../model/event';

@Injectable()
export class EventService {
  eventUrl = '/api/event/';

  constructor(private http: HttpClient) {}

  sendEvent(event: Event): Observable<Event> {
    return this.http.post<Event>(
      this.eventUrl +
        'action/' +
        event.action +
        '/type/' +
        event.actionType +
        '/contract/' +
        event.contractNr +
        '/identifier/' +
        event.identifier +
        '/queue/' +
        event.queue +
        '/msg/' +
        event.msg,
      event
    );
  }
}
