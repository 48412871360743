<h2>Statistiek</h2>
<app-filter
  [filterFields]="filtersToInclude"
  [filter]="filter"
  (applyFilter)="applyFilter()"
  (searchText)="applySearchText($event)"
  (resetFilter)="resetFilterAndSearch()"
></app-filter>
<br />
<mat-card appearance="outlined">
  <mat-card-content>
    <div class="mat-elevation-z0">
      <table
        mat-table
        matSort
        matSortDisableClear
        aria-label="Selecteer"
        [dataSource]="dataSource"
      >
        <ng-container matColumnDef="contractNr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract</th>
          <td mat-cell *matCellDef="let element">{{ element.contractNr }}</td>
        </ng-container>

        <ng-container matColumnDef="schoolName">
          <th mat-header-cell *matHeaderCellDef>School</th>
          <td mat-cell *matCellDef="let element">{{ element.schoolName }}</td>
        </ng-container>

        <ng-container matColumnDef="schoolYear">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Schooljaar</th>
          <td mat-cell *matCellDef="let element">{{ element.schoolYear }}</td>
        </ng-container>

        <ng-container matColumnDef="signUpYear">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aanmeldjaar</th>
          <td mat-cell *matCellDef="let element">{{ element.signUpYear }}</td>
        </ng-container>

        <ng-container matColumnDef="signUpId">
          <th mat-header-cell *matHeaderCellDef>AanmeldNr</th>
          <td mat-cell *matCellDef="let element">
            {{ element.signUpId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="isInflux">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Instroom</th>
          <td mat-cell *matCellDef="let element">
            {{ element.isInflux | jaNee }}
          </td>
        </ng-container>

        <ng-container matColumnDef="isImported">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Geïmporteerd
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.isImported | jaNee }}
          </td>
        </ng-container>

        <ng-container matColumnDef="isDeleted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Verwijderd</th>
          <td mat-cell *matCellDef="let element">
            {{ element.isDeleted | jaNee }}
          </td>
        </ng-container>

        <ng-container matColumnDef="signUpCreatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aangemaakt</th>
          <td mat-cell *matCellDef="let element">
            {{ element.signUpCreatedAt | date: "dd-MM-yyyy hh:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="modifiedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aangepast</th>
          <td mat-cell *matCellDef="let element">
            {{ element.modifiedAt | date: "dd-MM-yyyy hh:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="completedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Afgerond</th>
          <td mat-cell *matCellDef="let element">
            {{ element.completedAt | date: "dd-MM-yyyy hh:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acties</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index">
            <div class="button-container">
              <button
                mat-icon-button
                color="primary"
                [disabled]="rowIndex !== hoveredRow"
                (click)="export(element.signUpId, element.contractNr)"
                aria-label="exporteren"
                matTooltip="Exporteer"
              >
                <mat-icon>arrow_forward</mat-icon>
              </button>
              <button
                color="warn"
                (click)="onDelete(element.signUpId, element.contractNr)"
                [disabled]="rowIndex !== hoveredRow"
                mat-icon-button
                aria-label="verwijderen"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns; let rowIndex = index"
          (mouseover)="onMouseOver(rowIndex)"
          (mouseout)="onMouseOut()"
          (dblclick)="openEditor(row)"
          class="detail-row"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            Geen aanmeldingen gevonden
          </td>
        </tr>
      </table>
      <mat-paginator
        [length]="filter.totalItems"
        [pageSize]="filter.pageSize"
        [pageIndex]="filter.currentPage"
        [pageSizeOptions]="[10, 20, 50, 100]"
        (page)="pageEvent = onPageChange($event)"
        [showFirstLastButtons]="true"
        aria-label="Selecteer"
      >
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
